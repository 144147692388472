import {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {BubbleCorner} from 'Images/icons';
import {iconAsBackground} from 'Utils/lib';
import {mediaUp} from 'Utils/mediaQueries';

/**
 * Speechbubble
 *
 * @class Speechbubble
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Speechbubble extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        testId: PropTypes.string
    }

    static defaultProps = {testId: null}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Speechbubble
     */
    render() {
        const {children, testId} = this.props;

        return (
            <SpeechbubbleElement data-cy={testId}>
                {children}
            </SpeechbubbleElement>
        );
    }
}

export default Speechbubble;

const SpeechbubbleElement = styled.div`
    background-color: ${({theme}) => theme.colors.containerBackground};
    border-radius: 16px;
    padding: 24px 24px 31px;
    position: relative;
    width: 100%;

    &::before {
        border-style: solid;
        border-width: 0 30px 25px 30px;
        border-color: transparent transparent white transparent;
        content: '';
        height: 25px;
        left: 50%;
        position: absolute;
        top: -12.5px;
        transform: translate(-50%, 0);
        width: 43px;
    }

    ${mediaUp('md')} {
        border-radius: 0 16px 16px 16px;
        padding: 40px 54px 40px;

        &::before {
            background-image: url(${iconAsBackground(BubbleCorner)});
            background-position: top right;
            background-repeat: no-repeat;
            background-size: cover;
            border: 0;
            height: 75px;
            left: 0;
            top: 0;
            transform: translate(-100%, 0);
        }
    }
`;