import {Component} from 'react';

import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HistoryStore from 'Stores/HistoryStore';

const STAGGER_DELAY = 0.16;
const OPACITY_DELAY = 0.1;

const FadeInAnim = {
    enter({index}) {
        if (HistoryStore.getDirection() < 0) {
            return {
                opacity: 1,
                transition: {
                    opacity: {
                        delay: (index * STAGGER_DELAY) + OPACITY_DELAY,
                        duration: 0.65,
                        type: 'tween'
                    },
                    y: {
                        delay: index * STAGGER_DELAY,
                        duration: 0.75,
                        type: 'tween'
                    }
                },
                y: '0px'
            };
        }

        return {
            opacity: 1,
            transition: {
                opacity: {
                    delay: OPACITY_DELAY,
                    duration: 0.65,
                    type: 'tween'
                },
                y: {
                    duration: 0.75,
                    type: 'tween'
                }
            },
            y: '0px'
        };
    },
    exit() {
        if (HistoryStore.getDirection() >= 0) {
            return {
                opacity: 0,
                transition: {
                    opacity: {
                        duration: 0.65,
                        type: 'tween'
                    },
                    y: {
                        duration: 0.75,
                        type: 'tween'
                    }
                },
                y: '300px'
            };
        } else if (HistoryStore.getDirection() < 0) {
            return {
                opacity: 0,
                transition: {
                    opacity: {
                        duration: 0.65,
                        type: 'tween'
                    },
                    y: {
                        duration: 0.75,
                        type: 'tween'
                    }
                },
                y: '-300px'
            };
        }

        return {};
    },
    initial() {
        if (HistoryStore.getDirection() > 0) {
            return {
                opacity: 0,
                y: '-300px'
            };
        } else if (HistoryStore.getDirection() < 0) {
            return {
                opacity: 0,
                y: '300px'
            };
        }

        return {
            opacity: 1,
            y: '0px'
        };
    }
};

const resultFadeInAnim = {
    initial: {
        opacity: 0,
        x: '-7%'
    },
    enter({index, initial}) {
        // eslint-disable-next-line @nfq/no-magic-numbers
        let delay = (0.5 * index);

        if (initial) {
            delay = 0;
        } else if (window.matchMedia('(min-width: 769px)').matches) {
            // eslint-disable-next-line @nfq/no-magic-numbers
            delay = 1.25 + (0.5 * index);
        }

        return {
            opacity: 1,
            position: 'relative',
            transition: {
                delay,
                duration: 1
            },
            x: '0rem'
        };
    }
};

/**
 * FadeIn
 *
 * @class FadeIn
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class FadeIn extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        index: PropTypes.number.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        initial: PropTypes.bool,
        isResultPage: PropTypes.bool
    }

    static defaultProps = {
        initial: false,
        isResultPage: false
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof FadeIn
     */
    render() {
        const {children, index, initial, isResultPage} = this.props;

        return (
            <FadeInElement
                custom={{
                    index,
                    initial
                }}
                initial={initial ? 'enter' : null}
                variants={isResultPage ? resultFadeInAnim : FadeInAnim}
            >
                {children}
            </FadeInElement>
        );
    }
}

export default FadeIn;

const FadeInElement = styled(motion.div)`
    width: 100%;
`;