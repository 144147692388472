/* eslint-disable max-lines-per-function */
import {Component} from 'react';

import {Spacer} from '@nfq/spacer';
import Cookies from 'cookies';
import {autobind} from 'core-decorators';
import {motion} from 'framer-motion';
import jwt from 'jsonwebtoken';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'react-awesome-styled-grid';
import styled from 'styled-components';

import Button from 'Components/action/Button';
import Link from 'Components/action/Link';
import Avatar from 'Components/layout/Avatar';
import FadeIn from 'Components/layout/FadeIn';
import Hidden from 'Components/layout/Hidden';
import Speechbubble from 'Components/layout/Speechbubble';
import {H1, H2, Inline, Paragraph} from 'Components/layout/Text';
import HistoryStore from 'Stores/HistoryStore';

import PageLayout from 'Modules/PageLayout';

import {handleOnAnimationComplete} from 'Utils/utils';

const IndexAnimation = {
    enter: {},
    exit: {}
};

const DelayAnim = {
    exit: {
        opacity: 0,
        transition: {duration: 1.2}
    },
    initial: {opacity: 1}
};

/**
 * Homepage
 *
 * @export
 * @class Home
 * @extends {Component}
 */
export default class Home extends Component {
    static propTypes = {
        router: PropTypes.object.isRequired,
        next: PropTypes.func
    }

    static defaultProps = {next() {}}

    /**
     *Creates an instance of Home.
     *
     * @param {Object} props Component props.
    * @memberof Home
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Gets the layout for this page.
     *
     * @static
     * @param {Object} router        The router object from nextjs.
     * @param {Object} pageProps     All page props given from server.
     * @param {Object} PageComponent The page component to render.
     *
     * @returns {JSX} The complete page.
     * @memberof Home
     */
    static getLayout(router, pageProps, PageComponent) {
        HistoryStore.setStep(0);

        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <PageLayout router={router} {...pageProps}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <PageComponent router={router} step={1} {...pageProps} />
            </PageLayout>
        );
    }

    /**
     * Handles next page
     *
     * @memberof Home
     */
    @autobind
    handleNext() {
        const {next, router} = this.props;

        router.push(next());
    }

    /**
     * Returns the page.
     *
     * @returns {JSX} The page.
     * @memberof Home
     */
    render() {
        return (
            <AnimContainer
                animate="enter"
                exit="exit"
                initial="initial"
                variants={IndexAnimation}
                onAnimationComplete={handleOnAnimationComplete}
            >
                <StyledRow>
                    <Col
                        offset={{
                            lg: 0.75,
                            md: 0.5,
                            sm: 0,
                            xl: 1,
                            xs: 0
                        }}
                    >
                        <Row>
                            <FlexCol md={7}>
                                <Hidden sm xs>
                                    <Spacer maxX={5} x={5} />
                                </Hidden>
                                <FadeIn index={0}>
                                    <Paragraph mobileAlign="center" size="normal">
                                        Cloud-Telefonie, Team-Messaging, Online-Meetings, Mobilfunk.
                                    </Paragraph>
                                </FadeIn>
                            </FlexCol>
                        </Row>
                        <Spacer y={2} />
                        <Row>
                            <FlexCol md={7}>
                                <Hidden sm xs>
                                    <Spacer maxX={5} x={5} />
                                </Hidden>
                                <FadeIn index={1}>
                                    <H1>
                                        Finden Sie die richtige{' '}
                                        <Inline isHighlighted>Business<br /> Kommunikation für Ihr Unternehmen</Inline>
                                    </H1>
                                </FadeIn>
                            </FlexCol>
                        </Row>
                        <Spacer y={3} />
                        <Hidden lg md xl>
                            <Row justify="center">
                                <Col align="center" xs={2}>
                                    <FadeIn index={2}>
                                        <Avatar isBigger />
                                    </FadeIn>
                                </Col>
                            </Row>
                        </Hidden>
                        <Spacer y={2} />
                        <Row>
                            <Hidden sm xs>
                                <Spacer maxX={5} x={5} />
                            </Hidden>
                            <Col lg={6} md={6} sm={4} xl={8} xs={4} noGutter>
                                <FadeIn index={2}>
                                    <Speechbubble>
                                        <H2><Inline isHighlighted>Hi</Inline>, schön Sie zu sehen!</H2>
                                        <Spacer y={2} />
                                        <Paragraph size="normal">
                                            Ich bin Karina und zusammen finden wir die{' '}
                                            <Inline bold isHighlighted>passende Lösung</Inline> für Ihr Unternehmen.
                                            In nur <Inline bold isHighlighted noWrap>2 Minuten</Inline>{' '}
                                            finden wir heraus, welcher Tarif zu Ihnen passt.
                                        </Paragraph>
                                    </Speechbubble>
                                </FadeIn>
                            </Col>
                        </Row>
                        <Spacer y={4} />
                        <Row>
                            <FlexCol md={1} xs={4}>
                                <Hidden sm xs>
                                    <Spacer maxX={5} x={5} />
                                </Hidden>
                                <FadeIn index={3}>
                                    <Button text="Los geht's" isBigger onClick={this.handleNext} />
                                </FadeIn>
                            </FlexCol>
                        </Row>
                        <Spacer y={3} />
                        <Row>
                            <FlexCol>
                                <Hidden sm xs>
                                    <Spacer maxX={5} x={5} />
                                </Hidden>
                                <FadeIn index={4}>
                                    <Link
                                        href="https://cisco-placetel-lead.appointlet.com/s/produktberatung"
                                        target="_blank"
                                    >
                                        Lieber persönliche Beratung vereinbaren
                                    </Link>
                                </FadeIn>
                            </FlexCol>
                        </Row>
                    </Col>
                </StyledRow>
                <Delay variants={DelayAnim} />
            </AnimContainer>
        );
    }
}

const AnimContainer = styled(motion(Container))`
    height: 100%;
`;

const StyledRow = styled(Row)`
    align-items: center;
    display: flex;
    height: auto;
    min-height: 100%;
    padding-bottom: 40px;
    padding-top: 96px;
`;

const FlexCol = styled(Col)`
    display: flex;
    flex-direction: row;
`;

const Delay = styled(motion.div)``;

/**
 * Sets the CSRF token.
 *
 * @export
 * @param {Object} ctx     The context in which this function is called.
 * @param {Object} ctx.req The request object.
 * @param {Object} ctx.res The response object.
 * @returns {Object} An props object or an redirect.
 */
export async function getServerSideProps({req, res}) {
    const cookies = new Cookies(req, res);
    const {serverRuntimeConfig: {jwt: {expiresIn, issuer, secret}}} = getConfig();
    const CSRF = jwt.sign({}, secret, {
        expiresIn,
        issuer
    });

    cookies.set('csrf-token', CSRF, {
        // eslint-disable-next-line @nfq/no-magic-numbers
        expires: new Date(new Date().getTime() + (30 * 60000)),
        httpOnly: true,
        overwrite: true,
        sameSite: 'strict'
    });

    return {props: {}};
}