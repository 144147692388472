import {store} from '@risingstack/react-easy-state';

const HistoryStore = store({
    newStep: 0,
    prevStep: 0,
    getDirection() {
        return this.prevStep - this.newStep;
    },
    setStep(step) {
        if (step !== this.newStep) {
            this.prevStep = this.newStep;
            this.newStep = step;
        }
    }
});

export default HistoryStore;